import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';

const ShieldBlock = (props) => {
    const {
        BASE_URL = '',
        SITE = '',
        ZCODE = '',
        timeout = 5,
        QRCODE,
        QRLINK,
        CLIENTIP,
        MOBILE = false
    } = props;

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (props.DEBUG) {
                localStorage.debug = '*';
            } else {
                localStorage.removeItem('debug');
            }
        }
    }, [props.DEBUG]);

    const [socket, setSocket] = useState(null);
    const [key, setKey] = useState(null);

    useEffect(() => {


        let showQRTimer = setTimeout(() => {
            window.location.reload();
        }, timeout * 1000);
        // Create the socket connection
        const socketConnection = io("/", {
            path: '/vg-shield/socket/',//TODO: BASE_URL ???
            query: `vgCode=${ZCODE}`
        });

        // Store the socket connection in state
        setSocket(socketConnection);

        // Handle socket connection events
        socketConnection.on('connect', () => {
            socketConnection.emit('code', ZCODE);
            console.log('Socket connected');
        });

        socketConnection.on('disconnect', () => {
            clearTimeout(showQRTimer);
        });

        socketConnection.on('connect_error', (error) => {
            if (error.description === '403') {
                window.location.reload();
            }
        });

        socketConnection.on('key', (newKey) => {
            console.log('KEY:' + newKey);
            if (!key) {
                setKey(newKey);
                console.log('setKey(newKey);');
            } else if (key !== newKey) {
                window.location.reload();
                console.log('key !== newKey');
            }
        });

        socketConnection.on('reload', () => {
            window.location.reload(); // Reload the entire page
        })

        // socketConnection.on('message',  (data) => {
        //    console.log("message:"+data)// Reload the entire page
        // })
        // console.log("listening !!!");
        // Cleanup on component unmount: disconnect the socket
        return () => {
            clearTimeout(showQRTimer);

            console.log('Cleaning up socket connection');
            socketConnection.disconnect();
        };
    }, [BASE_URL, ZCODE, key, timeout]);



    return (
        <div className="shield-block">
            <div className="qr-wrapper">
                <div
                    className="qr-code-block"
                    dangerouslySetInnerHTML={{__html: props.QRCODE}}
                />

                <p className="client-ip">
                    <small>{CLIENTIP}</small>
                </p>
            </div>
            <div style={{display: 'none'}}>
                <div id="C">{ZCODE}</div>
            </div>
        </div>
    );
};

export default ShieldBlock;